<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        v-card
          v-toolbar(card, color='white')
            v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar tipo de identificador', v-model='search', hide-details)
            v-switch.mt-4(:false-value="0", :true-value="1", :color="filter.ativo ? 'success': ''", :label="filter.ativo ? 'Tipos de identificadores ativos' : 'Tipos de identificadores inativos'", v-model="filter.ativo")
            v-btn(color="bt-primary", @click.native="openDialogTipoIdentificador(null)").white--text Cadastrar
          v-divider
          v-card-text.pa-2
            v-data-table(:headers='headers', :search='search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading").elevation-0
              template(slot='items', slot-scope='props')
                td(width="5%").text-xs-center {{ props.item.id }}
                td.text-xs-left {{ props.item.nome }}
                td(width="15%").text-xs-center
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, @click.native="openDialogTipoIdentificador(props.item)")
                      v-icon edit
                    span Editar
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, :color="props.item.ativo ? 'red' : 'warning'", small, @click="changeAtivo(props.item)")
                      v-icon {{ props.item.ativo ? 'delete' : 'restore' }}
                    span {{ props.item.ativo ? 'Desativar' : 'Ativar' }}

    v-dialog(v-model="dialogTipoIdentificador", width="auto", scrollable, max-width="500px", persistent, v-if="dialogTipoIdentificador")
      v-card
        form(@submit.prevent="salvar('tipo_identificador')", data-vv-name="tipo_identificador")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline {{ tipo_identificadorEdit && tipo_identificadorEdit.id ? 'Editar tipo de identificador' : 'Cadastrar tipo de identificador' }}
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogTipoIdentificador = null; tipo_identificadorEdit = {}").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
          v-divider
          v-card-text
              v-layout(row, wrap)
                v-flex.xs12.md12
                  v-text-field(color="primary", label='Nome', required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", type="text", v-model='tipo_identificadorEdit.nome', autofocus)
          v-divider
          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap).pa-1
              v-flex.xs12.text-xs-right
                v-btn(color="black", flat, @click.native="dialogTipoIdentificador = false; tipo_identificadorEdit = {}") Fechar
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  data: () => ({
    filter: { ativo: 1 },
    tipos_identificadores: [],
    tipo_identificador: {},
    tipo_identificadorEdit: {},
    dialogTipoIdentificador: false,
    headers: [
      { text: '#', align: 'left', value: 'id' },
      { text: 'Nome', align: 'left', value: 'nome' },
      { text: 'Ações', align: 'center', sortable: false },
    ],
    search: '',
    loading: false
  }),
  computed: {
    list () {
      return _.filter(this.tipos_identificadores, { ativo: this.filter.ativo });
    }
  },
  mounted () {
    this.getTiposIdentificadores();
  },
  methods: {
    async getTiposIdentificadores () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/tipo-identificador');
        vm.tipos_identificadores = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async changeAtivo (tipo_identificador) {
      const vm = this;
      try {
        const obj = _.cloneDeep(tipo_identificador);
        obj.ativo = obj.ativo ? 0 : 1;
        const response = await vm.$axios.put(`/tipo-identificador/${obj.id}`, obj);
        tipo_identificador.ativo = response.data.ativo;
        window.getApp.$emit('APP_ALERT', { color: obj.ativo ? 'success' : 'orange', text: `Tipo de identificador ${tipo_identificador.ativo ? 'ativado' : 'desativado'} com sucesso!` });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o tipo de identificador. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async remove (tipo_identificador) {
      const vm = this;
      try {
        const res = await vm.$confirm('Deseja realmente excluir?<br> Essa ação não poderá ser desfeita!');
        if (!res) return;
        const response = await vm.$axios.delete(`/tipo-identificador/${tipo_identificador.id}`);
        vm.tipos_identificadores = _.reject(vm.tipos_identificadores, { id: tipo_identificador.id });
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Tipo de identificador excluído com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao excluir o tipo de identificador. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async save (tipo_identificador) {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.post('/tipo-identificador', tipo_identificador);
        if (response && response.data && response.data.id) {
          vm.tipos_identificadores.push(response.data);
          vm.tipo_identificador = false;
        }
        vm.dialogTipoIdentificador = false;
        vm.tipo_identificadorEdit = {};
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Tipo de identificador salva com sucesso!' });
      } catch (error) {
        vm.loading = false;
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o tipo de identificador. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async update (tipo_identificador) {
      const vm = this;
      try {
        const response = await vm.$axios.put(`/tipo-identificador/${tipo_identificador.id}`, tipo_identificador);
        const index = _.findIndex(vm.tipos_identificadores, { id: tipo_identificador.id });
        const tipos_identificadores = _.reject(vm.tipos_identificadores, { id: tipo_identificador.id });
        vm.tipos_identificadores = [...tipos_identificadores.slice(0, index), response.data, ...tipos_identificadores.slice(index)];
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Tipo de identificador salvo com sucesso!' });
        vm.dialogTipoIdentificador = false;
        vm.tipo_identificadorEdit = {};
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar o tipo de identificador. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        if (scope === 'tipo_identificador') {
          vm[vm.tipo_identificadorEdit.id ? 'update' : 'save'](vm.tipo_identificadorEdit);
        }
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async openDialogTipoIdentificador (tipo_identificador) {
      const vm = this;
      if (tipo_identificador) {
          vm.tipo_identificadorEdit = _.cloneDeep(tipo_identificador);
          if (vm.tipo_identificadorEdit && vm.tipo_identificadorEdit.id) {
          const response = await vm.$axios.get(`/tipo-identificador/${vm.tipo_identificadorEdit.id}`);
          vm.tipo_identificadorEdit = response.data;
          }
      }
      vm.dialogTipoIdentificador = true;
    }
  },
};
</script>

<style scoped>

</style>

